import Heading2 from "../../../components/text/Heading2";
import HeadingDescriptor from "../../../components/text/HeadingDescriptor";
import DevNote from "../../../components/dev/DevNote";
import Callout from "../../../components/text/Callout";
import {LightBulbIcon} from "@heroicons/react/24/outline";
import UploadFileDropZone from "../../../components/input-fields/UploadFileDropZone";
import {FieldValues, FormProvider, useForm} from "react-hook-form";
import MainButton from "../../../components/buttons/MainButton";
import {useState} from "react";
import axios from "axios";
import {getBackendURL} from "../../../utils/EnvironmentsManager";
import toast, {ErrorIcon} from "react-hot-toast";
import {useStoreProvider} from "../../../providers/StoreProvider";

interface DownloadShopifyThemeStepProps {
    visible: boolean
    onNextPressed: () => void
    setJobId: (id: string) => void
}

export const DownloadShopifyThemeStep = ({visible, onNextPressed, setJobId}: DownloadShopifyThemeStepProps) => {
    const methods = useForm()

    const [nextButtonDisabled, setNextButtonDisabled] = useState<boolean>(true)
    const {store} = useStoreProvider()

    const submitTheme = (values: any) => {
        // Access the uploaded file
        const uploadedFile = values.theme && values.theme[0];

        if (uploadedFile) {
            console.log('Uploaded file:', uploadedFile);
            const formData = new FormData();
            formData.append('zipFile', uploadedFile); // 'zipFile' matches the backend parameter name

            axios.post(getBackendURL() + "/api/v1/theme/update", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }).then(response => {
                // Handle success
                console.log('File uploaded successfully:', response.data);
                setJobId(response.data);
                onNextPressed && onNextPressed();
            }).catch(error => {
                // Handle error
                if (error.response.status == 400) {
                    console.log(error)
                    toast.error(error.response.data)
                }else {
                    toast.error("En ukjent feil har oppstått")
                }
            });
        } else {
            console.log('No file selected.');
        }
    };

    return (
        <>
            {visible && (
                <>
                    <Heading2>Steg 1: Last ned tema fra Shopify</Heading2>
                    <HeadingDescriptor leftAligned={true} withoutMargin={true}>
                        For å oppdatere temaet, må du laste ned ditt nåværende tema fra Shopify.
                    </HeadingDescriptor>
                    <Callout className={"my-5"}>
                        <div className={"flex flex-row gap-x-2 items-center font-semibonld"}>
                            <LightBulbIcon className={"w-6 text-purple-700 font-bold"}/>
                            Merk: Det er viktig at du laster ned tema på nytt fra Shopify for å unngå at du mister
                            endringer
                            du har gjort i Shopify
                        </div>
                    </Callout>
                    <p className={" mb-2"}>
                        1. Naviger til Shopify → Online Store.
                    </p>
                    <img src={"/enthemed-tutorial/update-theme/pre-download-tutorial1.jpg"} className={"max-w-full md:max-w-96"}/>

                    <p className={"mt-10 mb-2"}>
                        2. Klikk “•••” på ditt aktive tema og velg “Download theme file”.
                    </p>
                    <img src={"/enthemed-tutorial/update-theme/pre-download-tutorial2.jpg"} className={"max-h-52"}/>
                    <p className={"mt-10 mb-2"}>
                        3. Last opp zip-filen du lastet ned, i feltet under.
                    </p>
                    <FormProvider {...methods}>
                        <form onSubmit={methods.handleSubmit(submitTheme)}>
                            <UploadFileDropZone
                                idleText={"Dra temafilen inn hit, eller klikk for å velge"}
                                dropHoverText={"Slipp temafilen her"}
                                accept={{"application/zip": [".zip"]}}
                                name={"theme"}
                                onFileDropped={() => setNextButtonDisabled(false)}
                                onError={() => setNextButtonDisabled(true)}
                            />

                            <div className={"w-full flex flex-row justify-center mt-5"}>
                                <MainButton
                                    disabled={nextButtonDisabled}
                                >
                                    Gå videre
                                </MainButton>
                            </div>
                        </form>
                    </FormProvider>
                </>
            )}
        </>
    )
}