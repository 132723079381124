import React, {useEffect, useState} from 'react';
import {motion} from 'framer-motion'; // Import Framer Motion
import Heading2 from "../../../components/text/Heading2";
import MainButton from "../../../components/buttons/MainButton";
import {useStoreProvider} from "../../../providers/StoreProvider";
import {ThemeDTO} from "../../../types/ThemeDTO";
import toast from "react-hot-toast";
import {useNavigate} from "react-router";
import {EllipsisVerticalIcon} from "@heroicons/react/24/solid";
import KebabMenu, {KebabMenuItem} from "../../../components/modals/KebabMenu";
import {PopupComponent} from "../../../components/modals/PopupComponent";
import Heading1 from "../../../components/text/Heading1";
import HeadingDescriptor from "../../../components/text/HeadingDescriptor";
import Callout from "../../../components/text/Callout";
import SecondaryButton from "../../../components/buttons/SecondaryButton";

interface ThemeGridElementProps {
    theme: ThemeDTO
    onButtonClick: () => void;
    showTutorialClicked: () => void
}

const ThemeGridElement = ({theme, onButtonClick, showTutorialClicked}: ThemeGridElementProps) => {
    const {store} = useStoreProvider()
    const navigate = useNavigate()

    const [updateFromOldVersionPopupVisible, setUpdateFromOldVersionPopupVisible] = useState<boolean>(false)
    const [kebabMenuItems, setKebabMenuItems] = useState<KebabMenuItem[] | undefined>()

    useEffect(() => {
        let kebabMenuItems: KebabMenuItem[] = [
            {
                label: "Forhåndsvis tema",
                onClickAction: onButtonClick
            }
        ]

        if (store?.themeRelease && store?.themeRelease?.theme.id != theme.id) {
            kebabMenuItems = [...kebabMenuItems,
                {
                    label: "Bytt til tema",
                    onClickAction: onButtonClick
                }
            ]
        }

        if (store?.themeRelease?.theme.id === theme.id) {
            if (store.themeRelease.id != theme.latestRelease.id) {
                kebabMenuItems = [...kebabMenuItems,
                    {
                        label: "Oppdater tema",
                        onClickAction: updateThemeClicked
                    }
                ]
            }

            kebabMenuItems = [...kebabMenuItems,
                {
                    label: "Hvordan installerer jeg tema?",
                    onClickAction: showTutorialClicked
                }
            ]

            if (store.themeRelease.id == theme.latestRelease.id) {
                kebabMenuItems = [...kebabMenuItems,
                    {
                        label: "Oppdater fra eldre versjon",
                        onClickAction: updateFromOldVersionPopup
                    }
                ]
            }
        }
        setKebabMenuItems(kebabMenuItems)
    }, [store]);

    const updateFromOldVersionPopup = () => {
        setUpdateFromOldVersionPopupVisible(true)
    }

    const updateThemeClicked = () => {
        navigate("/update-theme?redirectUrl=/themes")
    }

    return (
        <div
            className="flex flex-col md:flex-col lg:flex-row items-center text-left shadow-lg p-6 md:p-10 rounded-3xl relative bg-white w-full">

            {updateFromOldVersionPopupVisible && (
                <PopupComponent
                    closePopup={() => setUpdateFromOldVersionPopupVisible(false)}
                    isVisible={updateFromOldVersionPopupVisible}
                >
                    <Heading1>Oppdater {theme.title}?</Heading1>
                    <HeadingDescriptor>
                        Dersom du har en eldre temafil som du vil oppdatere til nyeste versjon kan du gå
                        videre.
                    </HeadingDescriptor>
                    <Callout className={"text-center"}>
                        NB: Sørg for at temafilen er {theme.title}
                    </Callout>
                    <div className={"flex justify-center mt-5 gap-x-4"}>
                        <SecondaryButton
                            onClickEvent={() => setUpdateFromOldVersionPopupVisible(false)}>
                            Avbryt
                        </SecondaryButton>
                        <MainButton
                            onClickEvent={updateThemeClicked}
                        >
                            Oppdater tema
                        </MainButton>
                    </div>
                </PopupComponent>
            )}

            {store?.themeRelease?.theme.id === theme.id && (
                <>
                    {store.themeRelease.id == theme.latestRelease.id ? (
                        <div
                            className={"absolute px-6 py-3 rounded-tl-2xl rounded-br-2xl top-0 left-0 bg-gradient-to-r from-main-gradient-first to-main-gradient-second text-white font-semibold text-md"}>
                            Ditt aktive tema
                        </div>
                    ) : (
                        <div
                            className={"absolute px-6 py-3 rounded-tl-2xl rounded-br-2xl top-0 left-0 bg-gradient-to-r from-main-gradient-first to-main-gradient-second text-white font-semibold text-md"}>
                            Oppdatering tilgjengelig
                        </div>
                    )}

                </>
            )}

            <div className={"absolute right-5 top-5"}>
                {kebabMenuItems && kebabMenuItems.length > 0 && (
                    <KebabMenu menuItems={kebabMenuItems!}/>
                )}
            </div>

            <div className="w-full lg:w-1/2 mb-6 lg:mb-0 lg:mr-6">
                {/* Wrap the image with a motion.div */}
                <motion.div
                    whileHover={{scale: 1.02}} // More subtle zoom effect on hover
                    transition={{type: "tween", duration: 0.4, ease: "easeInOut"}} // Smooth, slow pan effect
                    className="rounded-lg overflow-hidden"
                >
                    <img src={theme.imageSrc} alt={theme.title} className="w-full h-full object-cover"/>
                </motion.div>
            </div>
            <div className="w-full lg:w-1/2 flex flex-col justify-start items-start">
                <Heading2 className="text-3xl font-bold mt-2">{theme.title}</Heading2>
                {store?.themeRelease && store.themeRelease.theme.id == theme.id && store.themeRelease.bundleBuild.version != theme.latestRelease.bundleBuild.version ? (
                    <p className={"text-gray-400 mb-4"}>v{store.themeRelease.bundleBuild.version} (<span
                        className={"bg-clip-text text-[#44444400] bg-gradient-to-r from-main-gradient-first to-main-gradient-second"}>v{theme.latestRelease.bundleBuild.version} tilgjengelig</span>)
                    </p>
                ) : (
                    <p className={"text-gray-400 mb-4"}>v{theme.latestRelease.bundleBuild.version}</p>
                )}
                <p className="text-gray-500 text-left text-md">
                    {theme.shortDescription}
                </p>
                {theme.badges && theme.badges.split(",").length > 0 && (
                    <div className="flex flex-wrap gap-2 mt-4">
                        {theme.badges.split(",").map((badgeText, index) => (
                            <span key={index}
                                  className="inline-flex items-center rounded-full bg-gradient-to-r from-blue-50 to-blue-100 px-2 py-1 text-xs font-medium text-blue-700">
                                {badgeText}
                            </span>
                        ))}
                    </div>
                )}
                {store && store.themeRelease?.theme.id == theme.id && store.themeRelease.id != theme.latestRelease.id ? (
                    <MainButton
                        onClickEvent={() => navigate("/update-theme?redirectUrl=/themes")}
                        className="mt-6">Oppdater nå</MainButton>
                ) : (
                    <MainButton
                        onClickEvent={onButtonClick}
                        className="mt-6">Forhåndsvis</MainButton>
                )}
            </div>
        </div>
    );
};

export default ThemeGridElement;
